<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>{{ customTitle }}</ion-title>
      <ion-button class="ion-margin-end" @click="closeModal" slot="end" fill="clear" size="small">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <template v-if="state.showHelp">
      <HelpText :text="'crimp_data_intro'"></HelpText>
    </template>
    <div v-for="(record, i) in records" :key="record" class="mkc-crimp-spec" :id="'crimp-spec-' + i">
      <header class="mkc-crimp-spec-header">
        <template v-if="!editingTitle">
          <h2>{{ customTitle }}
            <template v-if="records.length > 1"> #{{ i + 1 }}</template>
            <ion-button v-if="fromFavourites" @click.prevent="editTitle()" fill="clear" size="small">
              <ion-icon :icon="pencil" color="primary"></ion-icon>
            </ion-button>
          </h2>
        </template>
        <template v-else>
          <ion-item>
            <ion-input v-model="customTitle" @keyup.enter="saveTitle()" ref="editTitle" name="edit-title"></ion-input>
            <ion-button @click.prevent="saveTitle()" fill="clear" size="small" slot="end">
              <ion-icon :icon="save" color="primary"></ion-icon>
            </ion-button>
          </ion-item>
        </template>
        <ul>
          <li>Hose Type: {{ record['Hose Series'] }}</li>
          <li>Hose Size: {{ record['Hose Size'] }}</li>
          <li>Coupling Type: {{ record['Ferrule Series'] }}</li>
        </ul>

        <ion-button v-if="records.length > 1" @click="toggleDetails(i)" size="small" outline="clear">{{ detailsButtonLabel(i) }}</ion-button>
        <div class="mkc-crimp-spec-actions">
          <ion-button @click.prevent="printSpec('crimp-spec-' + i)"
                      fill="clear"
          >
            <ion-icon :icon="print" color="light"/>
          </ion-button>
          <ion-button v-if="isFavourite(record['Crimp Spec ID'])"
                      @click.prevent="removeFavourite(record['Crimp Spec ID'])"
                      fill="clear"
          >
            <ion-icon :icon="heart" color="light"/>
          </ion-button>
          <ion-button v-else
                      fill="clear"
                      @click.prevent="setFavourite(record['Crimp Spec ID'])"
          >
            <ion-icon :icon="heartOutline" color="light"/>
          </ion-button>
          <!--
          <ion-button @click="emailSpec('crimp-spec-' + i)"
                      fill="clear"
          >
            <ion-icon :icon="print" color="light"/>
          </ion-button>
          -->
        </div>
      </header>
      <div v-if="isOpen[i] || isOpen.length === 0" class="specs">
        <h2>Hose Settings</h2>

        <ion-list lines="none">
          <template v-for="(meta, name) in orderedData(record)" :key="name">
            <template v-if="meta !== '' && shouldDisplay(name)">
              <ion-item class="screen-only">
                <template v-if="dataType(meta) == 'longtext'">
                  <ion-label>
                    <h3>{{ name }}</h3>
                    <p class="wrap">
                      <span v-html="meta"></span>
                    </p>
                  </ion-label>

                </template>
                <template v-else>
                  <template v-if="dataType(meta) === 'url'">
                    <ion-label>
                      <h3><a :href="meta" target="_blank">{{ orderedData(record)[getLinkLabel(name)] }}</a></h3>
                    </ion-label>
                  </template>
                  <template v-else>
                    <template v-if="name === 'URL'">
                      <ion-label>
                        <img :src="'../assets/img-specs/' + meta" alt="Spec image">
                      </ion-label>
                    </template>
                    <template v-else>
                      <ion-label>
                        <h3>{{ name }}</h3>
                      </ion-label>
                      <ion-note slot="end">
                        {{ meta }}
                      </ion-note>
                    </template>
                  </template>
                </template>
              </ion-item>
              <!--BEGIN PRINT-->
              <div class="ion-item print-only">
                <template v-if="name === 'URL'">
                  <ion-label>
                    <img :src="'../assets/img-specs/' + meta" alt="Spec image">
                  </ion-label>
                </template>
                <template v-else>
                  <ion-label>
                    <strong>{{ name }}:&nbsp;&nbsp;</strong>
                  </ion-label>
                  <div class="ion-note">
                    {{ meta }}
                  </div>
                </template>
              </div>
            </template>
            <!--END PRINT-->
          </template>
        </ion-list>
      </div>
    </div>
  </ion-content>
</template>

<script>
import store from '@/store'
import {IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonItem, IonIcon, IonLabel, IonList, IonNote, IonInput, modalController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {chevronBackCircle, print, heart, heartOutline, close, pencil, save} from "ionicons/icons";
import HelpText from "@/components/HelpText";

export default defineComponent({
  name: "CrimpData",
  props: {
    title: {type: String, default: 'Your Crimp Spec'},
    crimpSpecId: {type: String, default: ''},
    line: {type: String, default: ''},
    fromFavourites: {type: Boolean, default: false}
  },
  components: {IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonItem, IonIcon, IonLabel, IonList, IonNote, IonInput, HelpText},
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };
    return {
      chevronBackCircle,
      print,
      heart,
      heartOutline,
      close,
      pencil,
      save,
      closeModal
    };
  },
  created() {
    this.localLine = (this.line !== '') ? this.line : this.state.line;
    this.customTitle = this.title;
    this.getRecords();
    this.setIsOpen();
  },
  data() {
    return {
      state: store.state,
      records: false,
      ignore: ['Crimp Spec ID', 'Coupling search data'],
      localLine: false,
      isOpen: [],
      editingTitle: false,
      customTitle: '',
    }
  },
  methods: {
    async getRecords() {
      //console.log(this.crimpSpecId);
      if (this.crimpSpecId !== '') {
        this.records = await store.getRecord('Crimp Spec ID', this.crimpSpecId, this.localLine);
      } else {
        this.records = Object.values(this.state.filteredCollection[this.state.line])
      }
    },
    dataType(string) {
      if (this.isValidHttpUrl(string)) {
        return 'url';
      } else if (String(string).length > 20) {
        return 'longtext';
      } else {
        return 'shorttext';
      }
    },
    isValidHttpUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    orderedData(array) {
      const ordered = Object.keys(array).sort().reduce(
          (obj, key) => {
            obj[key] = array[key];
            return obj;
          },
          {}
      );
      return ordered;
    },

    emailSpec(elementId) {
      const modal = document.getElementById(elementId)
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("print")

      if (!section) {
        section = document.createElement("div")
        section.id = "print"
        document.body.appendChild(section)
      }

      section.innerHTML = "";
      section.appendChild(cloned);

      window.open("mailto:?subject=Your Crimp Spec&body=" + section.innerHTML);
      //return section.innerHTML;
    },
    printSpec(elementId) {
      const modal = document.getElementById(elementId)
      const cloned = modal.cloneNode(true);
      //console.log(cloned);
      let section = document.getElementById("print")

      if (!section) {
        section = document.createElement("div")
        section.id = "print"
        document.body.appendChild(section)
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
    },
    setFavourite(name) {
      store.setFavourite(this.state.line, name)
    },
    removeFavourite(name) {
      store.removeFavourite(this.state.line, name)
    },
    editTitle() {
      this.editingTitle = true;

      setTimeout(() => {
        this.$refs.editTitle.$el.setFocus();
        this.$refs.editTitle.$el.getInputElement()
            .then((elem) => {
              elem.select();
            });
      }, 150);
    },
    saveTitle() {
      store.setCustomSpecName(this.localLine, this.crimpSpecId, this.customTitle)
      this.editingTitle = false;
    },
    isFavourite(name) {
      if (null === this.state.favourites
          || undefined === this.state.favourites[this.localLine]
          || !this.state.favourites[this.localLine].length) {
        return false;
      } else {
        return this.state.favourites[this.localLine].find(element => name === element.name) !== undefined;
      }
    },
    getLinkLabel(linkName) {
      return linkName.replace('URL', 'label')
    },
    shouldDisplay(name) {
      if (this.ignore.includes(name)) {
        return false;
      }
      const regex = new RegExp('(Link\\s[0-9]\\slabel+)', 'g');
      const result = !regex.test(name);
      return result;
    },
    toggleDetails(i) {
      this.isOpen[i] = !this.isOpen[i]
    },
    detailsButtonLabel(i) {
      if (this.isOpen[i]) {
        return 'Hide details';
      } else {
        return 'Show details';
      }
    },
    setIsOpen() {
      for (let i = 0; i < this.records.length; i++) {
        if (this.records.length === 1) {
          this.isOpen[i] = true;
        } else {
          this.isOpen[i] = false;
        }
      }
    }
  },
  computed: {
    numRecords() {
      return this.records.length
    },
  }
});
</script>

<style scoped>
h2 ion-button {
  position: relative;
  top: -7px;
}
p.wrap {
  white-space: break-spaces;
  font-size: 12px;
}

</style>
