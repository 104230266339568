<template>
  <div class="help-text" v-html="strings.help[text]"></div>
</template>

<script>
import store from '@/store'

export default {
  name: "HelpText",
  props: {'text': String},
  created() {
    //console.log(this.text);
  },
  data() {
    return {
      strings: store.strings,
    }
  },
}
</script>

<style scoped>

</style>
